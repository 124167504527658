import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true
  },
  {
    id: 32,
    name: "Exam Time",
    selector: (row) => row.meta.exam_time,
    sortable: true,
    reorder: true
  },
  {
    id: 33,
    name: "Availability",
    selector: (row) => row.meta.available_unavailable,
    sortable: true,
    reorder: true,
    width: "150px"
  },
  {
    id: 34,
    name: "Type",
    selector: (row) => row.meta.exam_type,
    sortable: true,
    reorder: true
  },
  {
    id: 3,
    name: "View",
    right: true,
    cell: (row) => <Link to={`${slugs.buy_exam_details}${row.slug}`}>View</Link>,

  }
];

export default function AllJapaneseExam() {
  const [Projects1, setProjects1] = React.useState([])
  const [Projects2, setProjects2] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [UBT, setUBT] = React.useState(0)
  const [Chapter, setChapter] = React.useState(0)
  
  const [Reload, setReload] = React.useState(false)
  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_buy_exam)
      .then((res) => {
        setProjects1(res.data)
        setProjects2(res.data)
        setCustomersList(res.data)
        setUBT(res.data.filter(a=>a.meta.exam_type=="UBT").length)
        setChapter(res.data.filter(a=>a.meta.exam_type=="Chapter").length)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllProjects({
      per_page: 100,
      _fields: "id,title,slug,date,meta.exam_time,meta.available_unavailable,meta.exam_type",
      "filter[meta_query][1][key]": "exam_type",
      "filter[meta_query][1][value][0]": "Japanese",
      "filter[meta_query][1][compare]": "=",
    })

  }, [])

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Japanese Exam</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Exam
        </div>
      </div>
      <a href={slugs.new_japanese_exam}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >Add New Exam</div>
      </a>
      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        <DataTable
          columns={columns}
          data={Projects2}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          striped
          // sortIcon={<SortIcon />}
          pagination
          selectableRows
        />
      </div>
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}