import React from "react";
import Database from '../../../Constant/database.json'
import { slugs } from "../../../Constant/slugs";
import FormComponent from "../Exam/Components/Form";
import ExamData from '../Exam/Components/examData.json'

export default function NewLokSewaExam() {
  const FetchData = {
    "id": "",
    "title": {
      "rendered": ""
    },
    "meta": {
      "exam_question_text": "[]",
      "exam_time": "",
      "exam_details": "",
      "available_unavailable": "Unavailable",
      "featured_image": "",
      "exam_type": "Lok Sewa"
    }
  };
  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>New Lok Sewa Exam</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / <a href={slugs.all_loksewa_exam}>All Student Exam</a>
        </div>
      </div>
      <FormComponent
        FetchData={FetchData}
        db_slug={slugs.db_slug_buy_exam}
        webSlug={slugs.buy_exam_details}
      />
    </div>
  )
}