import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import Database from '../../../../Constant/database.json'
import ImageUpload from "../../../ImageUpload";
import ExamQuestionModal from "./ExamQuestionModal";
import { CustomButtonUnSelect } from "../../../CustomButtomUnselect";
import ReactToPrint, { useReactToPrint } from 'react-to-print';

const QuestionBlock = [
  "question_number",
  "question",
  "question_description",
  "question_description_type",
  "question_media",
  "question_media_type",
  "option_1",
  "option_2",
  "option_3",
  "option_4",
  "answer_description",
  "correct_answer",
  "answer_media_type",
]

export default function FormComponent(props) {
  const { FetchData, db_slug, webSlug } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [Time, setTime] = React.useState(FetchData.meta.exam_time)
  const [Details, setDetails] = React.useState(FetchData.meta.exam_details)
  const [Available, setAvaliable] = React.useState(FetchData.meta.available_unavailable)
  const [FeaturedImage, setFeaturedImage] = React.useState(FetchData.meta.featured_image)
  const [ExamType, setExamType] = React.useState(FetchData.meta.exam_type)
  const [CopyTo, setCopyTo] = React.useState("")
  const [AudioSingle, setAudioSingle] = React.useState(FetchData.meta.media_non_repeat)
  const [ExamPrice, setExamPrice] = React.useState(FetchData.meta.exam_price)

  const [QuestionsBlock, setQuestionsBlock] = React.useState(true);
  const [QuestionsData, setQuestionsData] = React.useState(
    JSON.parse(FetchData.meta.exam_question_text)
  );
  const [ExamSubType, setExamSubType] = React.useState(
    db_slug == slugs.db_slug_buy_exam ?
      FetchData.meta.exam_sub_type : ""
  );
  const [QuestionsEditMode, setQuestionsEditMode] = React.useState(false);
  const [QuestionsEditData, setQuestionsEditData] = React.useState({});
  const [QuestionsEditKey, setQuestionsEditKey] = React.useState();

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const EditInfo = (POSTID, slug, passSlug) => {
    setReload(true)
    console.log(QuestionsData)
    var data;
    if (slug == "daily_exam")
      data = {
        "title": Title,
        "status": "publish",
        "meta": {
          "exam_question_block_text": JSON.stringify(QuestionsData),
          "exam_time": Time,
          "batch_id": batchID,
          "batch_name": batchName,
          "exam_details": Details,
          "available_unavailable": Available,
          "featured_image": FeaturedImage,
          "media_non_repeat": AudioSingle,
          "exam_type": ExamType,
        }
      }
    else if (slug == "demo_exam")
      data = {
        "title": Title,
        "status": "publish",
        "meta": {
          "exam_question_text": JSON.stringify(QuestionsData),
          "exam_time": Time,
          "exam_details": Details,
          "available_unavailable": Available,
          "featured_image": FeaturedImage,
          "media_non_repeat": AudioSingle,
          "exam_type": ExamType
        }
      }
    else
      data = {
        "title": Title,
        "status": "publish",
        "meta": {
          "exam_question_text": JSON.stringify(QuestionsData),
          "exam_time": Time,
          "exam_details": Details,
          "available_unavailable": Available,
          "featured_image": FeaturedImage,
          "media_non_repeat": AudioSingle,
          "exam_type": ExamType,
          "exam_sub_type": ExamSubType,
          "exam_price": ExamPrice
        }
      }
    // else
    //   data = {
    //     "title": Title,
    //     "status": "publish",
    //     "meta": {
    //       "exam_question_block_text": JSON.stringify(QuestionsData),
    //       "exam_time": Time,
    //       "exam_details": Details,
    //       "available_unavailable": Available,
    //       "featured_image": FeaturedImage,
    //       "media_non_repeat": AudioSingle,
    //       "exam_type": ExamType
    //     }
    //   }

    if (POSTID === "")
      AdsPOSTApi(data, slug)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Exam Added Successfully")
          console.log(res.data)
          history.push(`${passSlug}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slug, POSTID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const [batchID, setBatchID] = React.useState(FetchData.meta.batch_id)
  const [batchName, setBatchName] = React.useState(FetchData.meta.batch_name)
  const [batchModal, setBatchModal] = React.useState(false)
  const [batchList, setBatchList] = React.useState([])

  useEffect(() => {
    if (db_slug == "daily_exam")
      BatchList()
  }, [])
  const BatchList = () => {
    AdsGETApiAuth(
      { per_page: 100, _fields: "id,title,meta.batch_status,meta.batch_start_date,meta.batch_end_date" },
      slugs.db_slug_batch
    )
      .then((res) => {
        setBatchList(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
  const StudentExportRef5 = React.useRef(null);
  const StudentExportPrint5 = useReactToPrint({
    content: () => StudentExportRef5.current,
    pageStyle: { margin: "10px" },
    // pageStyle:`
    // margin:100px;
    // `,

  })

  const StudentExportRef4 = React.useRef(null);
  const StudentExportPrint4 = useReactToPrint({
    content: () => StudentExportRef4.current,
    pageStyle: { margin: "10px" },
    // pageStyle:`
    // margin:100px;
    // `,

  })


  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Exam Information</center></h4>

          <table>
            <tbody>
              <tr>
                <td className="col-3">Exam Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>

              <tr>
                <td className="col-3">Exam Time (in minutes)</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Time} placeholder="Exam Time"
                    onChange={(event) => { setTime(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Price</td>
                <td className="col-6">
                  <input className="input-common"
                    value={ExamPrice} placeholder="Exam Price"
                    onChange={(event) => { setExamPrice(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Details</td>
                <td className="col-6">
                  <textarea rows={5} className="input-common"
                    value={Details} placeholder="Exam Details"
                    onChange={(event) => { setDetails(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Featured Image</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={FeaturedImage}
                    setImageUrl={setFeaturedImage}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Audio Single</td>
                <td className="col-6">
                  <div style={{ display: "inline" }}>
                    <div style={{ display: "inline-block" }}>
                      <CustomButtonUnSelect setData={setAudioSingle} Data={AudioSingle} DataValue={"Single"} />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Name</td>
                <td className="col-6">
                  <CustomButton setData={setAvaliable} Data={Available} DataValue={"Available"} />
                  <CustomButton setData={setAvaliable} Data={Available} DataValue={"Unavailable"} />
                </td>
              </tr>

              <tr>
                <td className="col-3">Exam Type</td>
                <td className="col-6">
                  <div style={{ display: "inline" }}>
                    {db_slug == slugs.db_slug_demo_exam ?
                      <>
                        {Database.exam_type.map((item, index) => (
                          <div style={{ display: "inline-block" }} key={index}>
                            <CustomButton setData={setExamType} Data={ExamType} DataValue={item} />
                          </div>
                        ))}
                      </>
                      :
                      <>
                        {(ExamType == "UBT" || ExamType == "Agriculture" || ExamType == "Ship Building" || ExamType == "Manufacturing") ?
                          <div />
                          :
                          <CustomButton setData={setExamType} Data={ExamType} DataValue={ExamType} />
                        }
                      </>
                    }
                    {(ExamType == "UBT" || ExamType == "Agriculture" || ExamType == "Ship Building" || ExamType == "Manufacturing") &&
                      <>
                        <CustomButton setData={setExamType} Data={ExamType} DataValue={"UBT"} />
                        <CustomButton setData={setExamType} Data={ExamType} DataValue={"Agriculture"} />
                        <CustomButton setData={setExamType} Data={ExamType} DataValue={"Ship Building"} />
                        <CustomButton setData={setExamType} Data={ExamType} DataValue={"Manufacturing"} />
                      </>
                    }

                  </div>
                </td>
              </tr>
              {ExamType == "Others" &&
                <tr>
                  <td className="col-3">Exam Sub Type</td>
                  <td className="col-6">
                    <input className="input-common"
                      value={ExamSubType} placeholder="Exam SubType"
                      onChange={(event) => { setExamSubType(event.target.value) }}
                    />
                  </td>
                </tr>
              }

            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo(ID, db_slug, webSlug)
                }}
              >
                Update Exam
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Questions</center></h4>
          <div style={{ display: "inline", justifyContent: "flex-end" }}>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: QuestionsBlock ? "#000000" : "#444444", fontWeight: QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setQuestionsBlock(true)
              }}
            >
              Block
            </div>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: !QuestionsBlock ? "#000000" : "#444444", fontWeight: !QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setQuestionsBlock(false)
              }}
            >
              Table
            </div>
          </div>
          {(db_slug == slugs.db_slug_daily_exam || db_slug == slugs.db_slug_chapter_wise_exam || db_slug == slugs.db_slug_buy_exam) &&
            <div>
              <div className="row center m-2">
                <div className=" col-3" style={{ padding: "0 10px" }}>
                  <div className="sign-in-button-4"
                    onClick={() => {
                      setQuestionsEditData({
                        "question_number": `${Object.keys(QuestionsData).length + 1}`,
                        "question": "",
                        "question_description": "",
                        "question_description_type": "text",
                        "question_media": "",
                        "question_media_type": "none",
                        "option_1": "",
                        "option_2": "",
                        "option_3": "",
                        "option_4": "",
                        "answer_description": "",
                        "correct_answer": "option 1",
                        "answer_media_type": "text",
                      })
                      setQuestionsEditKey(`item-${Object.keys(QuestionsData).length}`)
                      setQuestionsEditMode(true)
                    }}
                  >
                    Add Question
                  </div>
                </div>
              </div>
            </div>
          }
          {QuestionsBlock ?
            <div>
              <div className="row m-4">
                <div className="col-6">
                  <div className="row">
                    {Object.values(QuestionsData).map((item, index) => {
                      if (index < Object.values(QuestionsData).length / 2)
                        return (
                          <div key={index} className="col-2 p-2 center"
                            style={{
                              margin: "5px",
                              backgroundColor: item.question !== "" ? "#fad4d4" : "#fafad4",
                              fontWeight: "bold",
                              border: "2px solid black",
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setQuestionsEditData(item)
                              setQuestionsEditKey(Object.keys(QuestionsData)[index])
                              setQuestionsEditMode(true)
                            }}
                          >
                            {item.question_number}
                          </div>
                        )
                    })}
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    {Object.values(QuestionsData).map((item, index) => {
                      if (index >= Object.values(QuestionsData).length / 2)
                        return (
                          <div key={index} className="col-2 p-2 center"
                            style={{
                              margin: "5px",
                              backgroundColor: item.question !== "" ? "#fad4d4" : "#fafad4",
                              fontWeight: "bold",
                              border: "2px solid black",
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setQuestionsEditData(item)
                              setQuestionsEditKey(Object.keys(QuestionsData)[index])
                              setQuestionsEditMode(true)
                            }}
                          >
                            {item.question_number}
                          </div>
                        )
                    })}
                  </div>
                </div>
              </div>
            </div>
            :
            <div style={{ overflowX: "scroll" }}>
              <div
                className="row"
                onClick={() => {

                }}
              >
                <div style={{ width: "5%", display: "inline", textAlign: "center" }}>QN</div>
                <div style={{ width: "30%" }}>Question</div>
                <div style={{ width: "15%", }}>Option 1</div>
                <div style={{ width: "15%", }}>Option 2</div>
                <div style={{ width: "15%", }}>Option 3</div>
                <div style={{ width: "15%", }}>Option 4</div>
                <div style={{ width: "5%", }}>Correct</div>
              </div>
              {Object.values(QuestionsData).map((item, index) => (
                <div key={index} style={{ cursor: "pointer", backgroundColor: index % 2 == 0 ? "#fff" : "#eee", padding: "5px 0" }}
                  className="row"
                  onClick={() => {
                    setQuestionsEditData(item)
                    setQuestionsEditKey(Object.keys(QuestionsData)[index])
                    setQuestionsEditMode(true)
                  }}
                >
                  <div style={{ width: "5%", display: "inline", textAlign: "center" }}>{item.question_number}</div>
                  <div style={{ width: "30%" }}>{item.question}</div>
                  <div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_1 : item.answer_media_type}</div>
                  <div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_2 : item.answer_media_type}</div>
                  <div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_3 : item.answer_media_type}</div>
                  <div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_4 : item.answer_media_type}</div>
                  <div style={{ width: "5%", }}>{item.correct_answer.replace("option ", "")}</div>
                </div>
              ))}
            </div>
          }
        </div>
      </div>

      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Make a copy to:</center></h4>
          <CustomButton setData={setCopyTo} Data={CopyTo} DataValue={"Student Exam"} />
          {db_slug == "daily_exam" &&
            <>
              {Object.keys(QuestionsData).length == 40 &&
                <>
                  <CustomButton setData={setCopyTo} Data={CopyTo} DataValue={"All Exam"} />
                  <CustomButton setData={setCopyTo} Data={CopyTo} DataValue={"Demo Exam"} />
                </>
              }
            </>
          }
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  if (CopyTo == "Batch Wise Exam")
                    EditInfo("", slugs.db_slug_daily_exam, slugs.daily_exam_details)
                  else if (CopyTo == "All Exam")
                    EditInfo("", slugs.db_slug_paid_exam, slugs.exam_details)
                  else if (CopyTo == "Student Exam")
                    EditInfo("", slugs.db_slug_buy_exam, slugs.buy_exam_details)
                  else
                    EditInfo("", slugs.db_slug_demo_exam, slugs.demo_exam_details)
                }}
              >
                Make a copy
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        QuestionsEditMode &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setQuestionsEditMode(false)
                    setQuestionsEditData([])
                    setQuestionsEditKey("")
                  }}
                >X</div>
                <ExamQuestionModal
                  oneQuestionData={QuestionsEditData}
                  keyValue={QuestionsEditKey}
                  AllQuestionData={QuestionsData}
                  setAllQuestionData={setQuestionsData}
                  PostExam={EditInfo}
                  setEditQuestion={setQuestionsEditMode}
                />
              </div>
            </div>
          </div>
        </section>
      }
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3 row">

          <div className=" col-3" style={{ padding: "0 10px" }}>
            <div className="sign-in-button-4"
              onClick={() => {
                StudentExportPrint5()
              }}
            >
              Convert to pdf With Answer
            </div>
          </div>
          <div className=" col-3" style={{ padding: "0 10px" }}>
            <div className="sign-in-button-4"
              onClick={() => {
                StudentExportPrint4()
              }}
            >
              Convert to pdf Without Answer
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ display: "none" }}
      >


        <div
          ref={StudentExportRef5} >
          <section style={{
            backgroundColor: "#fff",
            pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
          }}
          >
            <center>
              <h1 style={{ fontSize: 26 }}>고용허가제한국어능력시험<br />
                EPS-TOPIK
                <br />
                ({Title})
              </h1>

            </center>
            <div style={{ margin: 10, padding: 10, border: "1px solid #000", fontSize: "16px", fontWeight: "bold" }}>
              시험일자 ...............  년 ........ 월..........일 4부<br />
              Test date: ................. <br />
              문제지유형 : A형 / Test Type: A Type<br />
              문항수: 40 문항/시험시간: 50분
              {/* Number of questions: 40 questions/ Test time: 50 minutes. */}

            </div>
            <div style={{ marginLeft: 10, padding: 10, fontSize: "16px", fontWeight: "bold" }}>
              Number of questions: 40 questions/ Test time: 50 minutes.
            </div>
            <div style={{ margin: 10, padding: 10, border: "1px solid #000", fontSize: "16px", fontWeight: "bold" }}>
              ☆시험시작지시가있을때까지이문제지를펴지마십시오.<br />
              Please do not open the test booklet until instructed to do so.<br />
              <br />	☆각페이지의형벌, 문항수및인쇄상태를확인하시고이상이있을시
              <br />감독위원에게말씀하여주십시오
              <br />Please check type, number of questions and print condition of each Page and if
              there is any problem, report it to proctors immediately.
              <br />
              <br />☆응시번호와성명을문제지에기재하여야합니다.
              <br />You should write the application number and name on the test Papersheet and
              the answer sheet

            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: "50%", height: "100%", fontSize: "16px", fontWeight: "bold" }}>

              </div>
              <div style={{ width: "40%", height: "100%", textAlign: "right", fontSize: "16px", fontWeight: "bold" }}>
              </div>
            </div>
            <div style={{ marginLeft: 20, padding: 5, fontSize: "16px", fontWeight: "bold" }}>
              응시번호 (Application number): .....................<br />
              성명 (Name): ...................................<br /><br />
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ margin: 20, padding: 5, fontSize: "14px", fontWeight: "bold" }}>
                AIMS INTEGRATION EDUCATION PVT. LTD.<br />
                Machhapokhari, Kathmandu<br />
                (Opp. Global IME Bank)<br />
                Phone: 014987453/9851217472/9805717472/9851234991<br />
                Email: aims.edu22@gmail.com<br />
                Website: https://aimsintegrationeducation.com.np/    <br />
                MOBILE APP: http://aimsintegrationeducation.com.np/app<br />
                {/* HARI TIMSINA(9845445220) */}

              </div>
              <div style={{ width: "50%", height: "100%", textAlign: "right" }}>
                <img src="https://aimsintegrationeducation.com.np/wp-content/uploads/2023/07/cropped-logo.png"
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "contain",
                    // opacity: 0.3

                  }}
                />
                <img src="https://api.aimsintegrationeducation.com.np/wp-content/uploads/2023/10/Screenshot-2023-10-04-at-11.21.55.jpeg"
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "contain",
                    // opacity: 0.3

                  }}
                />

              </div>

            </div>
          </section>
          <div style={{
            zIndex: 1, position: "absolute", height: "100%",
          }}>
            <img src="https://api.aimsintegrationeducation.com.np/wp-content/uploads/2023/10/watermarkaims.jpeg"
              style={{
                width: "100vw",
                height: "100vh",
                objectFit: "contain",
              }}
            />
          </div>

          <div>
            <div id="content">
              <div className="col-12 form-view ques-print"
              >
                <div style={{ overflowX: "scroll", }}>
                  {Object.values(QuestionsData).map((oneQuestionData, index) => {

                    const lower = oneQuestionData.question.split(" ")[0].split("~")[0].replace(/\D/g, "");
                    return (
                      <section key={index} style={{
                        pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
                      }}
                        onClick={() => {
                          setQuestionsEditData(oneQuestionData)
                          setQuestionsEditKey(Object.keys(QuestionsData)[index])
                          setQuestionsEditMode(true)
                        }}
                      >
                        <div style={{
                          zIndex: 1, position: "absolute", height: "100%",
                        }}>
                          <img src="https://api.aimsintegrationeducation.com.np/wp-content/uploads/2023/10/watermarkaims.jpeg"
                            style={{
                              width: "100vw",
                              height: "100vh",
                              objectFit: "contain",
                              // opacity: 0.1
                            }}
                          />
                        </div>
                        <div style={{ zIndex: 10, position: "relative", }}>
                          <div style={{ padding: "0px 0 0 0", }}>

                            {oneQuestionData.question_number == "1" &&
                              <h3 style={{ textAlign: "center", fontSize: "22px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                읽기 ( 20 문항 )
                              </h3>
                            }
                            {oneQuestionData.question_number == 21 &&
                              <h3 style={{ textAlign: "center", fontSize: "22px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                듣기 ( 20 문항 )
                              </h3>
                            }
                            {lower == oneQuestionData.question_number &&
                              <h3 style={{ fontSize: "22px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                {oneQuestionData.question}
                              </h3>
                            }
                            <div style={{ display: "inline" }}>
                              <h3 style={{ display: "inline-block", fontSize: "22px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                {oneQuestionData.question_number}.
                              </h3>
                              {oneQuestionData.question_description_type == "text" &&
                                <div style={{
                                  display: "inline-block",
                                  fontSize: "20px",
                                  padding: "0 0 5px", margin: "0 0px"
                                }}>
                                  <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                                </div>
                              }
                            </div>
                          </div>
                          <div className="row"
                            style={{
                              // border: "1px solid #999",
                              borderRadius: "5px",
                              // margin: "5px",
                              padding: 0
                            }}>
                            {oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ?
                              <div className={
                                (oneQuestionData.option_1.length < 17 &&
                                  oneQuestionData.option_2.length < 17 &&
                                  oneQuestionData.option_3.length < 17 &&
                                  oneQuestionData.option_4.length < 17) ?
                                  "col-sm-6"
                                  :
                                  oneQuestionData.option_1.includes("https://")
                                    ? "col-sm-6" : "col-sm-12"
                              } >
                                <div>

                                  <div className="question-description ">
                                    {/* {oneQuestionData.question_description_type == "text" &&
                                          <div style={{
                                            fontSize: "20px",
                                            padding: "0 0 5px", margin: "0 40px"
                                          }}>
                                            <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                                          </div>
                                        } */}
                                    {oneQuestionData.question_description_type == "image" &&
                                      <>
                                        <div style={{ padding: "2px", textAlign: "center", }} >
                                          <img
                                            src={oneQuestionData.question_description}
                                            style={{
                                              height: "150px",
                                              // width: "392px",
                                              objectFit: 'contain',
                                              padding: "2px",
                                              border: "0.5px solid #999",
                                            }}
                                          />
                                        </div>
                                      </>
                                    }
                                    {/* {oneQuestionData.question_description_type == "audio" &&
                                          <div>
                                            <div style={{ margin: "0 40px" }}>
                                              <div style={{ cursor: "pointer" }} onClick={() => {
                                              }}>
                                                <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                                  style={{ width: "30px", height: "30px" }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        } */}
                                  </div>

                                  {oneQuestionData.question_media !== "" &&
                                    <div className="question-description col-10">
                                      {oneQuestionData.question_media_type == "text" &&
                                        <div style={{
                                          border: "0.5px solid #999",
                                          padding: "5px ",
                                          justifyContent: 'center',
                                          display: "flex"
                                        }}>
                                          <div style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
                                        </div>
                                      }
                                      {oneQuestionData.question_media_type == "image" &&
                                        <div style={{ textAlign: "center" }}>
                                          <img
                                            src={oneQuestionData.question_media}
                                            style={{
                                              height: "150px",
                                              // width: "392px",
                                              padding: "2px",
                                              objectFit: 'contain',
                                              border: "0.5px solid #999"
                                            }}
                                          />
                                        </div>
                                      }
                                      {/* {oneQuestionData.question_media_type == "audio" &&
                                            <div style={{ margin: "0 40px" }}>
                                              <div style={{ cursor: "pointer" }} >
                                                <div style={{ cursor: "pointer" }}>
                                                  <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                                    style={{ width: "30px", height: "30px" }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          } */}
                                    </div>
                                  }
                                </div>
                              </div>
                              :
                              <div className="col-md-12" >
                                <div style={{}}>
                                  {/* <div style={{ padding: "3px 0 0 0" }}>
                                      {lower == oneQuestionData.question_number &&
                                        <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                          {oneQuestionData.question}
                                        </h3>
                                      }
                                      <h3 style={{ fontSize: '18px', fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                        {oneQuestionData.question_number}.
                                      </h3>
                                    </div> */}
                                  <div className="question-description ">
                                    {/* {oneQuestionData.question_description_type == "text" &&
                                          <div style={{ fontSize: "15px", padding: "0 0 10px", margin: "0 40px" }}>
                                            <div style={{ fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                                          </div>
                                        } */}
                                    {oneQuestionData.question_description_type == "image" &&
                                      <>
                                        <div style={{ padding: "2px", textAlign: "center", }}>
                                          <img
                                            src={oneQuestionData.question_description}
                                            style={{ height: "70px", objectFit: 'contain', padding: "2px", border: "0.5px solid #000", }}
                                          />
                                        </div>
                                      </>
                                    }
                                  </div>
                                  {oneQuestionData.question_media !== "" &&
                                    <div className="question-description col-10" style={{ margin: "0 40px" }}>
                                      {oneQuestionData.question_media_type == "text" &&
                                        <div style={{ border: "0.5px solid #000", padding: "5px ", justifyContent: 'center', display: "flex" }}>
                                          <div style={{ fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
                                        </div>
                                      }

                                      {oneQuestionData.question_media_type == "image" &&
                                        <div style={{ textAlign: "center" }}  >
                                          <img
                                            src={oneQuestionData.question_media}
                                            style={{ height: "70px", padding: "2px", objectFit: 'contain', border: "0.5px solid #000" }}
                                          />
                                        </div>
                                      }
                                    </div>
                                  }
                                </div>
                              </div>
                            }
                            <div
                              className={oneQuestionData.answer_media_type == "image" ?
                                "col-sm-12 ms-5" :
                                oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ?
                                  (oneQuestionData.option_1.length < 17 &&
                                    oneQuestionData.option_2.length < 17 &&
                                    oneQuestionData.option_3.length < 17 &&
                                    oneQuestionData.option_4.length < 17) ?
                                    "col-sm-6"
                                    :
                                    oneQuestionData.option_1.includes("https://")
                                      ? "col-sm-6" : "col-sm-12 ms-5"

                                  : "col-sm-12"
                              }
                              style={{ padding: "0", margin: "auto" }}>
                              <div
                                className={oneQuestionData.answer_media_type == "image" ? "row" :
                                  oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ?
                                    "" : "row"}
                                style={{
                                  justifyContent: "center", alignContent: "center", alignItems: "center", height: "100%",
                                  margin: oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ? 0 : "0 50px"
                                }} >
                                <AnswerBlock5 correct_answer={oneQuestionData.correct_answer} option={"option 1"}
                                  oneQuestionData={oneQuestionData}
                                  index={1} DataValue={oneQuestionData.option_1} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 1"} />
                                <AnswerBlock5
                                  correct_answer={oneQuestionData.correct_answer} option={"option 2"}
                                  oneQuestionData={oneQuestionData} index={2} DataValue={oneQuestionData.option_2} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 2"} />
                                <AnswerBlock5
                                  correct_answer={oneQuestionData.correct_answer} option={"option 3"} oneQuestionData={oneQuestionData} index={3} DataValue={oneQuestionData.option_3} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 3"} />
                                <AnswerBlock5 correct_answer={oneQuestionData.correct_answer} option={"option 4"} oneQuestionData={oneQuestionData} index={4} DataValue={oneQuestionData.option_4} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 4"} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    )
                  })}
                </div>
                <div style={{ zIndex: 10, position: "relative" }}>
                  <div style={{ pageBreakInside: "avoid", display: "block", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }}>
                    <br />
                    <u><b>Answers</b></u>
                    <br />
                    <div style={{ border: "2px solid black", padding: "0px 10px", width: "90%", marginLeft: "10px" }}>
                      <div className="row" >
                        {Object.values(QuestionsData).map((oneQuestionData, index) => (
                          <section key={index} style={{ width: "10%", border: "1px solid black", }}>
                            <div className="row">
                              <div className="col-6" style={{ borderRight: "0.5px solid black", textAlign: "center", padding: "0px 0" }} >{index + 1}</div>
                              <div className="col-6" style={{ padding: "0px 0", textAlign: "center" }}>{oneQuestionData.correct_answer.replace("option ", "")}</div>
                            </div>
                          </section>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
      
      
      <div
      // style={{ display: "none" }}
      style={{ display: "none" }}
      >


        <div
          ref={StudentExportRef4} >
          <section style={{
            backgroundColor: "#fff",
            pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
          }}
          >
            <center>
              <h1 style={{ fontSize: 26 }}>고용허가제한국어능력시험<br />
                EPS-TOPIK
                <br />
                ({Title})
              </h1>

            </center>
            <div style={{ margin: 10, padding: 10, border: "1px solid #000", fontSize: "16px", fontWeight: "bold" }}>
              시험일자 ...............  년 ........ 월..........일 4부<br />
              Test date: ................. <br />
              문제지유형 : A형 / Test Type: A Type<br />
              문항수: 40 문항/시험시간: 50분

            </div>
            <div style={{ marginLeft: 10, padding: 10, fontSize: "16px", fontWeight: "bold" }}>
              Number of questions: 40 questions/ Test time: 50 minutes.
            </div>
            <div style={{ margin: 10, padding: 10, border: "1px solid #000", fontSize: "16px", fontWeight: "bold" }}>
              ☆시험시작지시가있을때까지이문제지를펴지마십시오.<br />
              Please do not open the test booklet until instructed to do so.<br />
              <br />	☆각페이지의형벌, 문항수및인쇄상태를확인하시고이상이있을시
              <br />감독위원에게말씀하여주십시오
              <br />Please check type, number of questions and print condition of each Page and if
              there is any problem, report it to proctors immediately.
              <br />
              <br />☆응시번호와성명을문제지에기재하여야합니다.
              <br />You should write the application number and name on the test Papersheet and
              the answer sheet

            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: "50%", height: "100%", fontSize: "16px", fontWeight: "bold" }}>

              </div>
              <div style={{ width: "40%", height: "100%", textAlign: "right", fontSize: "16px", fontWeight: "bold" }}>
              </div>
            </div>
            <div style={{ marginLeft: 20, padding: 5, fontSize: "16px", fontWeight: "bold" }}>
              응시번호 (Application number): .....................<br />
              성명 (Name): ...................................<br /><br />
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ margin: 20, padding: 5, fontSize: "14px", fontWeight: "bold" }}>
                AIMS INTEGRATION EDUCATION PVT. LTD.<br />
                Machhapokhari, Kathmandu<br />
                (Opp. Global IME Bank)<br />
                Phone: 014987453/9851217472/9805717472/9851234991<br />
                Email: aims.edu22@gmail.com<br />
                Website: https://aimsintegrationeducation.com.np/    <br />
                MOBILE APP: http://aimsintegrationeducation.com.np/app<br />
                {/* HARI TIMSINA(9845445220) */}

              </div>
              <div style={{ width: "50%", height: "100%", textAlign: "right" }}>
                <img src="https://aimsintegrationeducation.com.np/wp-content/uploads/2023/07/cropped-logo.png"
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "contain",
                    // opacity: 0.3

                  }}
                />
                <img src="https://api.aimsintegrationeducation.com.np/wp-content/uploads/2023/10/Screenshot-2023-10-04-at-11.21.55.jpeg"
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "contain",
                    // opacity: 0.3

                  }}
                />

              </div>

            </div>
          </section>
          <div style={{
            zIndex: 1, position: "absolute", height: "100%",
          }}>
            <img src="https://api.aimsintegrationeducation.com.np/wp-content/uploads/2023/10/watermarkaims.jpeg"
              style={{
                width: "100vw",
                height: "100vh",
                objectFit: "contain",
              }}
            />
          </div>

          <div>
            <div id="content">
              <div className="col-12 form-view ques-print"
              >
                <div style={{ overflowX: "scroll", }}>
                  {Object.values(QuestionsData).map((oneQuestionData, index) => {

                    const lower = oneQuestionData.question.split(" ")[0].split("~")[0].replace(/\D/g, "");
                    return (
                      <section key={index} style={{
                        pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
                      }}
                        onClick={() => {
                          setQuestionsEditData(oneQuestionData)
                          setQuestionsEditKey(Object.keys(QuestionsData)[index])
                          setQuestionsEditMode(true)
                        }}
                      >
                        <div style={{
                          zIndex: 1, position: "absolute", height: "100%",
                        }}>
                          <img src="https://api.aimsintegrationeducation.com.np/wp-content/uploads/2023/10/watermarkaims.jpeg"
                            style={{
                              width: "100vw",
                              height: "100vh",
                              objectFit: "contain",
                              // opacity: 0.1
                            }}
                          />
                        </div>
                        <div style={{ zIndex: 10, position: "relative", }}>
                          <div style={{ padding: "0px 0 0 0", }}>
                            {oneQuestionData.question_number == "1" &&
                              <h3 style={{ textAlign: "center", fontSize: "22px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                읽기 ( 20 문항 )
                              </h3>
                            }
                            {oneQuestionData.question_number == 21 &&
                              <h3 style={{ textAlign: "center", fontSize: "22px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                듣기 ( 20 문항 )
                              </h3>
                            }
                            {lower == oneQuestionData.question_number &&
                              <h3 style={{ fontSize: "22px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                {oneQuestionData.question}
                              </h3>
                            }
                            <div style={{ display: "inline" }}>
                              <h3 style={{ display: "inline-block", fontSize: "22px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                {oneQuestionData.question_number}.
                              </h3>
                              {oneQuestionData.question_description_type == "text" &&
                                <div style={{
                                  display: "inline-block",
                                  fontSize: "20px",
                                  padding: "0 0 5px", margin: "0 0px"
                                }}>
                                  <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                                </div>
                              }
                            </div>
                          </div>
                          <div className="row"
                            style={{
                              // border: "1px solid #999",
                              borderRadius: "5px",
                              // margin: "5px",
                              padding: 0
                            }}>
                            {oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ?
                              <div className={
                                (oneQuestionData.option_1.length < 17 &&
                                  oneQuestionData.option_2.length < 17 &&
                                  oneQuestionData.option_3.length < 17 &&
                                  oneQuestionData.option_4.length < 17) ?
                                  "col-sm-6"
                                  :
                                  oneQuestionData.option_1.includes("https://")
                                    ? "col-sm-6" : "col-sm-12"
                              } >
                                <div>

                                  <div className="question-description ">
                                    {/* {oneQuestionData.question_description_type == "text" &&
                                          <div style={{
                                            fontSize: "20px",
                                            padding: "0 0 5px", margin: "0 40px"
                                          }}>
                                            <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                                          </div>
                                        } */}
                                    {oneQuestionData.question_description_type == "image" &&
                                      <>
                                        <div style={{ padding: "2px", textAlign: "center", }} >
                                          <img
                                            src={oneQuestionData.question_description}
                                            style={{
                                              height: "150px",
                                              // width: "392px",
                                              objectFit: 'contain',
                                              padding: "2px",
                                              border: "0.5px solid #999",
                                            }}
                                          />
                                        </div>
                                      </>
                                    }
                                    {/* {oneQuestionData.question_description_type == "audio" &&
                                          <div>
                                            <div style={{ margin: "0 40px" }}>
                                              <div style={{ cursor: "pointer" }} onClick={() => {
                                              }}>
                                                <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                                  style={{ width: "30px", height: "30px" }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        } */}
                                  </div>

                                  {oneQuestionData.question_media !== "" &&
                                    <div className="question-description col-10">
                                      {oneQuestionData.question_media_type == "text" &&
                                        <div style={{
                                          border: "0.5px solid #999",
                                          padding: "5px ",
                                          justifyContent: 'center',
                                          display: "flex"
                                        }}>
                                          <div style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
                                        </div>
                                      }
                                      {oneQuestionData.question_media_type == "image" &&
                                        <div style={{ textAlign: "center" }}>
                                          <img
                                            src={oneQuestionData.question_media}
                                            style={{
                                              height: "150px",
                                              // width: "392px",
                                              padding: "2px",
                                              objectFit: 'contain',
                                              border: "0.5px solid #999"
                                            }}
                                          />
                                        </div>
                                      }
                                      {/* {oneQuestionData.question_media_type == "audio" &&
                                            <div style={{ margin: "0 40px" }}>
                                              <div style={{ cursor: "pointer" }} >
                                                <div style={{ cursor: "pointer" }}>
                                                  <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                                    style={{ width: "30px", height: "30px" }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          } */}
                                    </div>
                                  }
                                </div>
                              </div>
                              :
                              <div className="col-md-12" >
                                <div style={{}}>
                                  {/* <div style={{ padding: "3px 0 0 0" }}>
                                      {lower == oneQuestionData.question_number &&
                                        <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                          {oneQuestionData.question}
                                        </h3>
                                      }
                                      <h3 style={{ fontSize: '18px', fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                        {oneQuestionData.question_number}.
                                      </h3>
                                    </div> */}
                                  <div className="question-description ">
                                    {/* {oneQuestionData.question_description_type == "text" &&
                                          <div style={{ fontSize: "15px", padding: "0 0 10px", margin: "0 40px" }}>
                                            <div style={{ fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                                          </div>
                                        } */}
                                    {oneQuestionData.question_description_type == "image" &&
                                      <>
                                        <div style={{ padding: "2px", textAlign: "center", }}>
                                          <img
                                            src={oneQuestionData.question_description}
                                            style={{ height: "70px", objectFit: 'contain', padding: "2px", border: "0.5px solid #000", }}
                                          />
                                        </div>
                                      </>
                                    }
                                  </div>
                                  {oneQuestionData.question_media !== "" &&
                                    <div className="question-description col-10" style={{ margin: "0 40px" }}>
                                      {oneQuestionData.question_media_type == "text" &&
                                        <div style={{ border: "0.5px solid #000", padding: "5px ", justifyContent: 'center', display: "flex" }}>
                                          <div style={{ fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
                                        </div>
                                      }

                                      {oneQuestionData.question_media_type == "image" &&
                                        <div style={{ textAlign: "center" }}  >
                                          <img
                                            src={oneQuestionData.question_media}
                                            style={{ height: "70px", padding: "2px", objectFit: 'contain', border: "0.5px solid #000" }}
                                          />
                                        </div>
                                      }
                                    </div>
                                  }
                                </div>
                              </div>
                            }
                            <div
                              className={oneQuestionData.answer_media_type == "image" ?
                                "col-sm-12 ms-5" :
                                oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ?
                                  (oneQuestionData.option_1.length < 15 &&
                                    oneQuestionData.option_2.length < 15 &&
                                    oneQuestionData.option_3.length < 15 &&
                                    oneQuestionData.option_4.length < 15) ?
                                    "col-sm-6"
                                    :
                                    oneQuestionData.option_1.includes("https://")
                                      ? "col-sm-6" : "col-sm-12 ms-5"

                                  : "col-sm-12"
                              }
                              style={{ padding: "0", margin: "auto" }}>
                              <div
                                className={oneQuestionData.answer_media_type == "image" ? "row" :
                                  oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ?
                                    "" : "row"}
                                style={{
                                  justifyContent: "center", alignContent: "center", alignItems: "center", height: "100%",
                                  margin: oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ? 0 : "0 50px"
                                }} >
                                <AnswerBlock5 correct_answer={oneQuestionData.correct_answer} option={"option 1"}
                                  oneQuestionData={oneQuestionData}
                                  index={1} DataValue={oneQuestionData.option_1} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 1"} />
                                <AnswerBlock5
                                  correct_answer={oneQuestionData.correct_answer} option={"option 2"}
                                  oneQuestionData={oneQuestionData} index={2} DataValue={oneQuestionData.option_2} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 2"} />
                                <AnswerBlock5
                                  correct_answer={oneQuestionData.correct_answer} option={"option 3"} oneQuestionData={oneQuestionData} index={3} DataValue={oneQuestionData.option_3} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 3"} />
                                <AnswerBlock5 correct_answer={oneQuestionData.correct_answer} option={"option 4"} oneQuestionData={oneQuestionData} index={4} DataValue={oneQuestionData.option_4} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 4"} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div >
  )

}

const AnswerBlock51 = ({ index, DataType, DataValue, oneQuestionData }) => {
  return (
    <div className={DataType == "text" ?
      (oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image") ?
        "col-12" :
        "col-6"
      :
      "col-6"}
      style={{ display: 'flex', padding: "5px 10px", }}>
      <div style={{ marginRight: "5px", cursor: "pointer" }}  >
        <div style={{
          border: "1px solid black",
          borderRadius: "20px", width: "30px",
          height: "30px", display: "flex", justifyContent: "center", alignContent: "center",
          fontSize: "20px", fontWeight: "bold"
        }}>
          &nbsp;{index}&nbsp;
        </div>
      </div>
      <div className="passive-option2">
        <div style={{ fontSize: "18px" }}>{DataType == "text" && `${DataValue}`}</div>
        {DataType == "image" && <img src={DataValue} style={{ height: "130px", border: "0.5px solid #000" }} />}
        {DataType == "audio" &&
          <div>
            <a href={DataValue} target="_blank">
              <div style={{ cursor: "pointer" }} onClick={() => { }}>
                <div>
                  <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                    style={{ width: "20px", height: "20px" }}
                  />
                </div>
              </div>
            </a>
          </div>
        }
      </div>
    </div>
  )
}


const AnswerBlock5 = ({ index, DataType, DataValue, oneQuestionData, correct_answer, option }) => {
  return (
    <div className={DataType == "text" ?
      (oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image") ?
        "col-12"
        :
        (oneQuestionData.option_1.length < 5 &&
          oneQuestionData.option_2.length < 5 &&
          oneQuestionData.option_3.length < 5 &&
          oneQuestionData.option_4.length < 5)
          ?
          "col-3"
          :

          (oneQuestionData.option_1.length < 16 &&
            oneQuestionData.option_2.length < 16 &&
            oneQuestionData.option_3.length < 16 &&
            oneQuestionData.option_4.length < 16)
            ?
            "col-6"
            : "col-12"
      :
      "col-6"}
      style={{
        borderLeft: 0,
        // borderBottom: correct_answer == option ? "2px solid green" : 0,
        display: 'flex', padding: "2px 10px",
      }}>
      <div style={{ marginRight: "5px", cursor: "pointer" }}  >
        <div style={{
          // border: option == correct_answer ? "2px solid green" : "2px solid black",
          border: "2px solid black",
          backgroundColor: "#fff",
          color: "#000",
          // border: "1px solid black",
          // borderRadius: "20px", width: "30px", height: "30px", 
          borderRadius: "20px", width: "20px", height: "20px",
          display: "flex", justifyContent: "center", alignContent: "center",
          fontSize: "12px",
          // fontWeight: "bold"
        }}>
          &nbsp;{index}&nbsp;
        </div>
      </div>
      <div className="passive-option2">
        <div style={{ fontSize: "18px" }}>{DataType == "text" && `${DataValue}`}</div>
        {DataType == "image" && <img src={DataValue} style={{ height: "170px", width: "100%", objectFit: "contain", border: "0.5px solid #000" }} />}
        {DataType == "audio" &&
          <div>
            <a href={DataValue} target="_blank">
              <div style={{ cursor: "pointer" }} onClick={() => { }}>
                <div>
                  <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                    style={{ width: "20px", height: "20px" }}
                  />
                </div>
              </div>
            </a>
          </div>
        }
      </div>
    </div>
  )
}